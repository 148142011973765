import {defineStore} from 'pinia'
import {authStore} from "~/stores/auth.js"

export const userStore = defineStore('user', {
    state: () => ({
        isFormDisabled: false,
    }),

    actions: {
        async changeActiveWorkspace(workspace) {
            return useNuxtApp().$api(`/workspaces/change-to/${workspace.id}`, {
                method: 'POST',
            })
                .then(() => {
                    window.location.href = `/settings/workspace?workspace_changed_to=${workspace.id}`
                })
        },

        async updateAccount(body) {
            return useNuxtApp().$api('/user/account', {
                method: 'PATCH',
                body,
            })
                .then((response) => {
                    useNuxtApp().$bus.$emit('flash', {
                        type: 'success',
                        title: 'Your account has been updated.',
                    })

                    authStore().initAuth()
                })
        },

        async updateSecurity(body) {
            this.isFormDisabled = true

            return useNuxtApp().$api('/user/security', {
                method: 'PATCH',
                body,
            })
                .then(() => {
                    this.isFormDisabled = false

                    useNuxtApp().$bus.$emit('flash', {
                        type: 'success',
                        title: 'Your security settings have been updated.',
                    })
                    authStore().initAuth()
                })
                .catch(error => {
                    this.isFormDisabled = false

                    throw error
                })
        },
    },
})
